<script
    lang="ts"
    setup
>
    import type { AppTablePagination } from '~/ts/types/app'

    type Props = AppTablePagination & { visiblePageCount?: number, pending?: boolean }

    type Emit = {
        (event: 'go-to-page', value: number): void
    }

    const props = withDefaults(defineProps<Props>(), {
        visiblePageCount: 3,
        pending: false,
    })

    const emit = defineEmits<Emit>()

    const visiblePageNumbers = computed(() => {
        const pageCount = Math.min(props.visiblePageCount, props.pageCount)
        const range = Math.floor(pageCount / 2)

        let start = props.currentPage - range
        let end = props.currentPage + range

        if (start <= 0) {
            start = 1
            end = Math.min(pageCount, props.pageCount)
        }

        if (end > props.pageCount) {
            start = Math.max(1, props.pageCount - pageCount + 1)
            end = props.pageCount
        }

        return Array.from(
            { length: end - start + 1 },
            (_, i) => start + i,
        )
    })

    const goToPage = (page: number): void => {
        if (props.currentPage === page) {
            return
        }

        emit('go-to-page', page)
    }
</script>

<template>
    <div
        v-show="props.pageCount"
        :class="$style['table-pagination']"
    >
        <button
            key="previous"
            type="button"
            :disabled="props.pending || (currentPage === 1)"
            :class="$style['table-pagination-arrow-button']"
            @click="goToPage(currentPage - 1)"
        >
            <AppIconChevronLeft size="20" />
        </button>

        <button
            v-if="visiblePageNumbers[0] !== 1"
            key="first-page"
            type="button"
            :disabled="props.pending"
            :class="$style['table-pagination-page-button']"
            @click="goToPage(1)"
        >
            1
        </button>

        <span
            v-if="visiblePageNumbers[0] > 2"
            key="separator-1"
            :class="$style['table-pagination-separator']"
        >
            ...
        </span>

        <button
            v-for="pageNumber in visiblePageNumbers"
            :key="pageNumber"
            type="button"
            :disabled="props.pending"
            :class="[ $style['table-pagination-page-button'], { [$style['active']]: pageNumber === currentPage } ]"
            @click="goToPage(pageNumber)"
        >
            {{ pageNumber }}
        </button>

        <span
            v-if="visiblePageNumbers[visiblePageNumbers.length - 1] < props.pageCount - 1"
            key="separator-2"
            :class="$style['table-pagination-separator']"
        >
            ...
        </span>

        <button
            v-if="visiblePageNumbers[visiblePageNumbers.length - 1] !== props.pageCount"
            key="last-page"
            type="button"
            :disabled="props.pending"
            :class="$style['table-pagination-page-button']"
            @click="goToPage(props.pageCount)"
        >
            {{ props.pageCount }}
        </button>

        <button
            key="next"
            type="button"
            :disabled="props.pending || (currentPage === props.pageCount)"
            :class="$style['table-pagination-arrow-button']"
            @click="goToPage(currentPage + 1)"
        >
            <AppIconChevronRight size="20" />
        </button>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .table-pagination
        display: flex
        align-items: center
        justify-content: center
        gap: 2px
        width: 100%
        margin: 40px 0

        &-page-button,
        &-arrow-button,
        &-separator
            max-width: 34px
            max-height: 34px
            min-width: 34px
            min-height: 34px
            padding: 8px
            vertical-align: middle
            font: inherit
            font-style: normal
            text-align: center
            font-size: 14px
            font-weight: 400
            line-height: 130%

        &-page-button,
        &-arrow-button
            appearance: none
            white-space: nowrap
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            outline: none
            border: none
            border-radius: 8px
            user-select: none

        &-separator
            display: flex
            align-items: flex-end

        &-page-button:not(.active),
        &-arrow-button
            &
                background: none

            &:hover
                background: rgba(220, 224, 227, 0.3)

            &:active
                background: #e3e5eb

            &[disabled]
                opacity: 0.6
                background: none !important
                cursor: not-allowed

        &-page-button.active
            &
                background: var(--color-primary)

            &:hover:not(.active)
                background: #efcc1a

            &:active:not(.active)
                background: #efb31a

            &[disabled]
                opacity: 0.6
                background: #fff5c6 !important
                cursor: not-allowed
</style>
